import React from 'react';
//Import our custom bootstrap implementation
import './scss/custom.scss';


/* Content Imports */
import LogoRow from './components/LogoRow.js';
import TitleRow from './components/TitleRow.js';
import AssessmentBlock from './components/AssessmentBlock.js';
import Carousel from './components/Carousel.js';
import MountainWaving from './components/MountainWaving.js';
import ExperienceBlock from './components/ExperienceBlock.js';
import Success from './components/Success.js';
import Campfire from './components/Campfire.js';

function App() {
    return (
        <>
          <div className="bg-blue-gradient mb-0 pb-0">
            <div className="wrapper mx-auto">
              <LogoRow></LogoRow>
              <TitleRow></TitleRow>
              <AssessmentBlock></AssessmentBlock>
              
              <Carousel></Carousel>
            </div>          
            <MountainWaving></MountainWaving>
            <ExperienceBlock></ExperienceBlock>
            <Success></Success>
            <Campfire></Campfire>
          </div>
        </>
    )
  }

export default App;
