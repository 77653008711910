import {
    Row,
    Col,
    Image
} from 'react-bootstrap';

import Logo from '../assets/Landing Page Logo Grouping.png';

export default function LogoRow(){
    return(
        <div className="pt-5">
            <Row className="justify-content-center pt-5 pb-5">
                <Col style={{minWidth: 100}} xs={11} md={8}>
                    <Image className="img-fluid" src={Logo}></Image>
                </Col>
            </Row>
        </div>
    )
}