
import React, {useRef} from 'react';
import {
    Container,
    Button,
    Col,
    Row,
    Image,
    Form
} from 'react-bootstrap';

import Script from 'next/script'

import axios from 'axios';

import GiftImage from '../assets/3_Gift@3x.png';

export default function AddressBlock(props){
    

    //Redemption form fill
    const RedeemGift = () => {

        const formEl = useRef(null);
        const submitButton = useRef(null);

        
        const injectGA = () => {
            if (typeof window == 'undefined') {
                return;
            }
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-1V51SPQ0VZ');
        };
        const captureOutboundLink = function(url) {
            if (typeof window !== 'undefined') {   
            window.gtag('send', 'event', 'outbound', 'click', url, {
                'transport': 'beacon',
                'hitCallback': function(){document.location = url;}
            });
            return false;}
        }

        const formSubmit = (event) => {
            event.preventDefault();
            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6LdhIjkcAAAAAHesagjuD_WZGN5xfBjIViA7VMEO', {action: 'submit'}).then(function(token) {
                    submitButton.current.disabled = true;
                    formEl.current.classList.add('was-validated');
                    if(formEl.current.checkValidity()){
                        let payload = {
                            token:token
                        };
        
                        //Grab all the form elements, spread into array, for each element append id and value to payload
                        let formElements = formEl.current.elements;
                        formElements = [...formElements];
                        formElements.forEach(formElement => {
                            if (formElement.value) {
                                payload[formElement.id] = formElement.value;
                            }
                        });
                
                        //Send to form processing endpoint
                        axios.post('https://prod-140.westus.logic.azure.com:443/workflows/43b80f6adf4e4fc99777cb61986a2503/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=7t_SAgeGckNw7UyALquC065HN-qRCjkM47N1gRppeR0', payload)
                            //success
                            .then(() => {
                                console.log('success')
                                submitButton.current.classList.remove('text-dark-blue');
                                submitButton.current.classList.remove('bg-white');
                                submitButton.current.classList.remove('border-3');
                                submitButton.current.classList.add('btn-success');
                                submitButton.current.textContent = 'Success!';
                                submitButton.current.style.opacity = 1;
                            })
                            //error
                            .catch(error => {
                                if(error.response.status === 409){
                                    submitButton.current.classList.remove('text-dark-blue');
                                    submitButton.current.classList.remove('bg-white');
                                    submitButton.current.classList.remove('border-3');
                                    submitButton.current.classList.add('btn-danger');
                                    submitButton.current.textContent = 'You have already submitted this form';
                                    submitButton.current.style.opacity = 1;
                                }
                                else{
                                    alert('There was an error processing your submission, please reload the page and try again');
                                }
                            })
                    }
                    else{
                        submitButton.current.disabled = false;
                    }
              });
            });
        }

        return(
            <>
            <Script 
                src="https://www.google.com/recaptcha/api.js?render=6LdhIjkcAAAAAHesagjuD_WZGN5xfBjIViA7VMEO"
            />
            
            {/* Global site tag (gtag.js) - Google Analytics */}
            <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-1V51SPQ0VZ"
            />
            <Script>{injectGA()}</Script>
            <div className="assessment-step">
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={10} className="pt-4 mx-auto">
                <h3 className="ms-2 mt-4 text-salesforce-blue SalesforceSans-Bold">
                    <a className="text-salesforce-blue text-decoration-none" href="https://polsource.com/10-keys-to-success/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener" onClick={() => captureOutboundLink('https://polsource.com/10-keys-to-success/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener')} target="blank">Download the 10 Keys to Success</a>
                    </h3>
                </Col>
            </Row>
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={10} className="mx-auto">
                    <Row className="justify-content-center w-100 mx-auto">
                        <Col lg={6} className="mx-auto">
                            <Row className="justify-content-center justify-content-lg-end mt-4">
                                <Col className="text-center">
                                    <p className="text-start text-white SalesforceSans-Light fs-4">
                                    We love that you’re thinking about how a great experience can make your customers and your employees happier. Make sure to check out the EPAM PolSource <a className="text-white" href="https://polsource.com/10-keys-to-success/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener"  onClick={() => captureOutboundLink('https://polsource.com/10-keys-to-success/?utm-source=polSource&utm-medium=email&utm-campaign=salesforce-door-opener')} target="blank">10 Keys to Success</a> to help you transform business processes and improve the experiences your company delivers!
                                    </p>
                                    <Image 
                                        style={{maxHeight:300, maxWidth:375}} 
                                        className="img-fluid mx-auto" 
                                        src={GiftImage}
                                    ></Image>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                        <Row className="justify-content-center mt-4 justify-content-lg-start">
                            <Col className="text-start">
                                <p className="text-white SalesforceSans-Light fs-4">
                                    Now, it’s time for us to spread the happiness.
                                </p>
                                <p className="text-white SalesforceSans-Light fs-4">
                                    Where can we send your free gift?
                                </p>
                                <Form ref={formEl} onSubmit={formSubmit}  className="needs-validation" noValidate>
                                    <Row>
                                        <Col>  
                                            <div className="my-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="256" id="firstName" placeholder="First Name" required />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="my-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="256" id="lastName" placeholder="Last Name" required />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pb-0">
                                        <Col xs={8}>  
                                            <div className="mt-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="70" id="address" placeholder="Street Address" required />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mt-2">
                                                <input type="text" className="form-control form-input-pill" maxLength="70" id="address2" placeholder="Apt/Suite" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="my-3">
                                        <input type="text" className="form-control form-input-pill" maxLength="70" placeholder="City" id="city" required />
                                    </div>
                                    <div className="my-3">
                                        <input className="form-control form-input-pill" maxLength="70" name="state" id="state" placeholder="State" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="70" className="form-control form-input-pill" id="postalCode" placeholder="Postal Code" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="70" className="form-control form-input-pill" id="country" placeholder="Country" required />
                                    </div>
                                    <div className="my-3">
                                        <input type="text" maxLength="50" className="form-control form-input-pill" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" id="phone" placeholder="Phone Number (optional)" />
                                    </div>
                                    <Col className="text-center">
                                        <Button
                                            className="g-recaptcha my-2 w-100 mx-auto text-dark-blue bg-white border-3"

                                            size="lg"
                                            ref={submitButton}
                                            type="Submit"
                                        >
                                            Submit To Receive Your Free Gift
                                        </Button>
                                        <p style={{opacity: '0.85'}} className="text-white">
                                            <small>
                                            By submitting your contact information you confirm that you would like to receive a gift from Salesforce subject to the offer terms below. Your information may be shared with Salesforce, along with program sponsor EPAM Polsource, and used as described in <a className="text-white text-decoration-none" href="https://www.salesforce.com/company/privacy/" onClick={() => captureOutboundLink('https://www.salesforce.com/company/privacy/')}>Salesforce's Privacy Statement</a>. 
                                            </small>
                                        </p>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        </>
        )
    }
    

    
    return(
        <>
            <Container className="step-container pb-3 mt-4">
                {RedeemGift()}
            </Container>  
        </>
    )
}