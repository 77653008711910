import {
    Col,
    Row
} from 'react-bootstrap';
import AddressBlock from './AddressBlock.js';

export default function AssessmentBlock(props){
    return(
        <>  
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={8} xl={8} className="py-lg-3 mx-auto border-top border-white border-2">
                </Col>
            </Row>
            <Row>
                <AddressBlock></AddressBlock>
            </Row>
            <Row className="w-100 mx-auto">
                <Col xs={10} md={10} lg={8} xl={8} className="pt-lg-4 mx-auto border-bottom border-white border-2">
                </Col>
            </Row>
        </>
    )
}