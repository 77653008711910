
import {
    Col,
    Row,
    Image
} from 'react-bootstrap';

import Pointing from '../assets/Gifs/Pointing.gif';

export default function ExperienceBlock(){

    return(
        <div className="sand-wave">
            <Col xs={12} lg={11} className="wrapper experience-card mx-auto py-5 h-100">
                <h1 className="SalesforceSans-Bold text-dark-blue text-center pt-5 mt-1 mt-md-0">
                    Experience really is everything
                </h1>
                <Row className="mt-5 w-100 mx-auto">
                    <Col className="px-0 px-md-2" xs={12} lg={6}>
                        <Col className="mx-auto" xs={10} lg={9}>
                            <h3 className="SalesforceSans-Bold">
                                Happy customers
                            </h3>
                            <p className="lead text-left mt-3">
                                When you use the world's #1 CRM to connect to your customers in a more intelligent way they experience:
                            </p>
                            <ul className="custom-bullets position-relative ms-4">
                                <li className="lead my-4">Consistent across sales,<br></br>service, marketing, <br></br>commerce, IT, and analytics</li>
                                <li className="lead my-4">Digital convenience across<br></br> their preferred communication channels</li>
                                <li className="lead my-4">Personalized service tailored <br></br>to their needs</li>
                                <li className="lead my-4">More satisfaction and <br></br>brand loyalty</li>
                            </ul> 
                        </Col>
                    </Col>
                    <Col className="px-0" xs={12} lg={6}>
                        <Col className="mx-auto" xs={10} lg={9}>
                            <h3 className="SalesforceSans-Bold mt-4 mt-lg-0">
                                Happy employees 
                            </h3>
                            <p className="lead text-left mt-3">
                                Empowered employees serve customers better and the Salesforce Customer Success Platform works hard to: 
                            </p>
                            <ul className="custom-bullets position-relative ms-4">
                                <Col xs={12} sm={9} md={6} lg={8} xl={7}>
                                    <li className="lead my-4">Lighten your team’s workload while doing some heavy lifting for your business</li>
                                </Col>
                                <li className="lead my-4">Automate repetitive tasks</li>
                                <li className="lead my-4">Make collaboration effortless, <br></br>regardless of where people <br></br>are working from</li>
                                <li className="lead my-4">Offer ease of use and more <br></br>functionality</li>
                                <li className="lead my-4">Keep sales and service on<br></br> the same page</li>
                                <li className="lead my-4">Enable speed, efficiency,<br></br> and growth</li>
                            </ul> 
                        </Col>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <div className="position-relative">
                <div className="pointing-wrapper">
                    <Image
                        src={Pointing}
                        className="pointing contrast-image"
                        style={{zIndex: 0}}
                    ></Image>
                </div>
            </div>
            </Col>
        </div>
    )
}