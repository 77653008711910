import React from 'react';
import { 
    Row,
    Container,
    Image
} from 'react-bootstrap';

import Plane from '../assets/Gifs/AnimatedPlane.gif';


export default function TitleRow(){
    return(
        <>
            <Row className="w-100 mx-auto">
                <div className="py-5 text-center text-white">
                    <div className="pb-2">
                        <h1 className="display-5 SalesforceSans-Light mb-4 mb-lg-0">
                            Happier customers. Happier employees.
                        </h1>
                        <h1 className="display-5 SalesforceSans-Light">
                            Accelerated business growth.
                        </h1>
                    </div>
                    <p className="fw-bold fs-4 mt-4 text-salesforce-blue" style={{letterSpacing: 4}}>
                        IT'S ALL WITHIN REACH.
                    </p>
                </div>
            </Row>
            <Container className="d-none d-lg-flex" style={{position: 'relative'}}>
                <div className="airplane-wrapper">
                    <Image className="airplane" src={Plane}></Image>
                </div>
            </Container>
        </>
    )
}
